<template>
  <main class="product page_bg_grey blank-aside-js" :class = "(isOpenAside=='true')?'blank-aside':''">
    <section v-if="productData" class="product__top">
      <div class="card communication-product__card m-t-30">
        <ProductCard v-if="supplierData" :supplierData="supplierData" :productData="productData" :isExpensive="false"></ProductCard>
        <CardSocials></CardSocials>
      </div>

      <div class="confirm-phone product__confirm-phone">
        <div class="confirm-phone__container">
          <div class="confirm-phone__wrapper">
            <div class="confirm-phone__title">
              <span
                class="confirm-phone__price product__confirm-phone-phone-price.price">
                {{ $t('COMMON.PRICE') }}: </span>
              <b
                class="confirm-phone__price-value product__confirm-phone-phone-price.value"
                >{{ ' ' + productData.price + ' ' }} ₪ </b> 
            </div>

            <form class="confirm-phone__form" action="">
              <div class="confirm-phone__enter">
                <label class="confirm-phone__label" for="phone_number">
                  {{ $t('GIFTCARD_PRODUCT.PHONE_NUMBER') }}
                </label>
                <input
                  id="phone_number"
                  class="input confirm-phone__input"
                  type="tel"
                  v-model="payment.phone_number"
                  v-model.lazy="$v.payment.phone_number.$model"
                  :class="{ 'validation-error': $v.payment.phone_number.$error }"
                />
              </div>
            </form>
            <a
              id="payment_submit"
              @click="handlePaymentSubmit"
              class="link confirm-phone__button"
              >{{ $t('COMMON.TIME') }}</a
            >
          </div>
        </div>
      </div>
    </section>

    <section class="m-t-40" v-if="supplierData && expensiveProduct && expensiveProduct.length">
      <h2 class="title title__h2 product__title m-b-10">{{ $t('SUPPLIER_PAYMENT.UPGRADE_TO') }}</h2>
      <div class="d-flex">
        <div class="card communication-product__card before-none">
          <ProductCard :supplierData="supplierData" :productData="expensiveProduct[0]" :isExpensive="true"></ProductCard>
        </div>
      </div>
      <h4 @click="goUpgradeProduct" class="upgrade-product">{{ $t('COMMON.TIME') }}</h4>
    </section>

    <section class="product__similar" v-if="supplierData && expensiveProduct && expensiveProduct.length">
      <h2 class="title title__h2 product__title">{{ $t('GIFTCARD_PRODUCT.SIMILAR_PRODUCTS') }}</h2>
      <div class="category__wrapper">
        <CategoryUnit
          v-for="(product_item, index) in expensiveProduct"
          :key="index"
          class="category__unit"
          :productItem="product_item"
          currentSupplierProductCategory="default"
          :supplierData="supplierData"
          :productData="productData"
          :supplierId="supplierId"
          :isSupplierCategoryItem="false"
        ></CategoryUnit>
      </div>
    </section>

    <section
      id="payment-confirmation-modal"
      class="modal-default"
      :class="{ 'modal-default_active': paymentConfirmationModal.active }"
    >
      <div class="modal-default__container">
        <button
          class="button modal-default__close"
          @click="cancelPaymentHandler"
        >
          <img src="/assets/img/close.svg" alt="" />
        </button>

        <h2 class="title title__h2 modal-default__title">
          {{
            $t('COMMUNICATION_PRODUCT.CONFIRMATION_PHONE_NUMBER_MODAL_TITLE')
          }}
        </h2>

        <p
          class="text modal-default__text"
          v-html="
            $t(
              'COMMUNICATION_PRODUCT.CONFIRMATION_PHONE_NUMBER_MODAL_TEXT',
            ).indexOf('[phone_number]') !== -1
              ? $t(
                  'COMMUNICATION_PRODUCT.CONFIRMATION_PHONE_NUMBER_MODAL_TEXT',
                ).replace('[phone_number]', formattedPhoneNumber)
              : $t(
                  'COMMUNICATION_PRODUCT.CONFIRMATION_PHONE_NUMBER_MODAL_TEXT',
                ) +
                ' ' +
                formattedPhoneNumber
          "
        ></p>

        <button
          class="button modal-default__confirm"
          @click="approvePaymentHandler"
        >
          {{ $t('COMMON.OK') }}
        </button>

        <button
          class="button cancel-button modal-default__cancel"
          @click="cancelPaymentHandler"
        >
          {{ $t('COMMON.CANCEL') }}
        </button>
      </div>
    </section>
    <loading :active.sync="loading"
             :can-cancel="false"
             :is-full-page="true"></loading>
  </main>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import appConfig from '../../appConfig';
import { validationMixin } from 'vuelidate';
import { required, minLength, maxLength } from 'vuelidate/lib/validators';

import CardSocials from '../../components/communication/CardSocials'
import ProductCard from '../../components/communication/ProductCard'
import CategoryUnit from '../../components/communication/CategoryUnit'

export default {
  mixins: [validationMixin],
  name: 'CommunicationProduct',
  components: {
    CardSocials,
    ProductCard,
    CategoryUnit
  },
  data: () => {
    return {
      payment: {
        phone_number: '',
        confirmed: false,
      },
      paymentConfirmationModal: {
        active: false,
      },
    };
  },
  validations: {
    payment: {
      phone_number: {
        required,
        minLength: minLength(10),
        maxLength: maxLength(10),
      },
    },
  },
  computed: {
    ...mapState({
      supplier_items: (state) => state.supplier.supplier_items,
      suppliers: (state) => state.supplier.suppliers,
      suppliers_products: (state) => state.supplier.suppliers_products,
      products: (state) => state.product.products,
      paymentRequestInProgress: (state) => state.payment.paymentRequestInProgress,
    }),
    loading() {
        return !!this.paymentRequestInProgress;
    },
    productId() {
      return this.$route.params.id;
    },
    supplierId() {
      return this.$route.params.supplier_id;
    },
    upscaleProductId() {
      return this.productData ? this.productData.upscale_product_id : null;
    },
    upscaleProductSupplierId() {
      return this.upscaleProductData
        ? this.upscaleProductData.supplier_id
        : null;
    },
    productData() {
      let productDataFromSuppliersData = null;

      if (this.suppliers_products) {
        for (let supplier_id in this.suppliers_products) {
          for (let supplier_product_type in this.suppliers_products[
            supplier_id
          ]) {
            for (let supplier_product of this.suppliers_products[supplier_id][
              supplier_product_type
            ]) {
              if (
                supplier_product.hasOwnProperty('id') &&
                supplier_product.id == this.productId
              ) {
                productDataFromSuppliersData = { ...supplier_product };
                break;
              }
            }
          }
        }
      }

      let productDataFromProductsData = null;
      if (this.products && this.productId in this.products) {
        productDataFromProductsData = { ...this.products[this.productId] };
      }

      let productData = productDataFromProductsData
        ? productDataFromProductsData
        : productDataFromSuppliersData;
      return productData ? productData : null;
    },
    productDetailsText() {
      if (
        !this.productData ||
        !this.supplierData ||
        this.supplierData.type != 1
      ) {
        return '';
      }

      let productDetails = '';
      let productDetailsRowTemplate = '<p>[row_text]</p>';
      if (
        this.productData.hasOwnProperty('call_minute') &&
        this.productData.call_minute != '' &&
        this.productData.call_minute != null
      ) {
        productDetails += productDetailsRowTemplate.replace(
          '[row_text]',
          'דקות שיחה:' + ' ' + this.productData.call_minute,
        );
      }
      if (
        this.productData.hasOwnProperty('sms') &&
        this.productData.sms != '' &&
        this.productData.sms != null
      ) {
        productDetails += productDetailsRowTemplate.replace(
          '[row_text]',
          'הודעות:' + ' ' + this.productData.sms,
        );
      }
      if (
        this.productData.hasOwnProperty('browsing_package') &&
        this.productData.browsing_package != '' &&
        this.productData.browsing_package != null
      ) {
        productDetails += productDetailsRowTemplate.replace(
          '[row_text]',
          'נפח גלישה:' + ' ' + this.productData.browsing_package,
        );
      }
      if (
        this.productData.hasOwnProperty('usage_time_limit') &&
        this.productData.usage_time_limit != '' &&
        this.productData.usage_time_limit != null
      ) {
        productDetails += productDetailsRowTemplate.replace(
          '[row_text]',
          'תוקף בימים:' + ' ' + this.productData.usage_time_limit,
        );
      }
      if (
        this.productData.hasOwnProperty('call_terms') &&
        this.productData.call_terms != '' &&
        this.productData.call_terms != null
      ) {
        productDetails += productDetailsRowTemplate.replace(
          '[row_text]',
          'תנאי שיחות:' + ' ' + this.productData.call_terms,
        );
      }
      if (
        this.productData.hasOwnProperty('call_to_palestinian') &&
        this.productData.call_to_palestinian != false &&
        this.productData.call_to_palestinian != null
      ) {
        productDetails += productDetailsRowTemplate.replace(
          '[row_text]',
          'שיחות לרשות:' + ' ' + '✓',
        );
      }
      if (
        this.productData.hasOwnProperty('abroed_price') &&
        this.productData.abroed_price != '' &&
        this.productData.abroed_price != null
      ) {
        productDetails += productDetailsRowTemplate.replace(
          '[row_text]',
          "מחיר לשימוש בחו''ל ובארץ:" + ' ' + this.productData.abroed_price,
        );
      }
      if (
        this.productData.hasOwnProperty('other1') &&
        this.productData.other1 != '' &&
        this.productData.other1 != null
      ) {
        productDetails += productDetailsRowTemplate.replace(
          '[row_text]',
          'אחר 1:' + ' ' + this.productData.other1,
        );
      }
      if (
        this.productData.hasOwnProperty('other2') &&
        this.productData.other2 != '' &&
        this.productData.other2 != null
      ) {
        productDetails += productDetailsRowTemplate.replace(
          '[row_text]',
          'אחר 2:' + ' ' + this.productData.other2,
        );
      }

      return productDetails;
    },
    supplierData() {
      let supplierDataFromSuppliersItemsData = null;
      if (this.supplier_items) {
        for (let supplier_type in this.supplier_items) {
          for (let suppliers_type_item of this.supplier_items[supplier_type]) {
            if (suppliers_type_item.id == this.supplierId) {
              supplierDataFromSuppliersItemsData = { ...suppliers_type_item };
              break;
            }
          }
        }
      }

      let supplierDataFromSuppliersData = null;
      if (this.suppliers && this.supplierId in this.suppliers) {
        supplierDataFromSuppliersData = { ...this.suppliers[this.supplierId] };
      }

      let supplierData = supplierDataFromSuppliersData
        ? supplierDataFromSuppliersData
        : supplierDataFromSuppliersItemsData;
      return supplierData ? supplierData : null;
    },
    upscaleProductData() {
      let upscaleProductDataFromSuppliersData = null;

      if (this.suppliers_products) {
        for (let supplier_id in this.suppliers_products) {
          for (let supplier_product_type in this.suppliers_products[
            supplier_id
          ]) {
            for (let supplier_product of this.suppliers_products[supplier_id][
              supplier_product_type
            ]) {
              if (
                supplier_product.hasOwnProperty('id') &&
                supplier_product.id == this.upscaleProductId
              ) {
                upscaleProductDataFromSuppliersData = { ...supplier_product };
                break;
              }
            }
          }
        }
      }

      let upscaleProductDataFromProductsData = null;
      if (this.products && this.upscaleProductId in this.products) {
        upscaleProductDataFromProductsData = {
          ...this.products[this.upscaleProductId],
        };
      }

      let upscaleProductData = upscaleProductDataFromProductsData
        ? upscaleProductDataFromProductsData
        : upscaleProductDataFromSuppliersData;
      return upscaleProductData ? upscaleProductData : null;
    },
    upscaleProductSupplierData() {
      let upscaleProductSupplierDataFromSuppliersItemsData = null;
      if (this.supplier_items) {
        for (let supplier_type in this.supplier_items) {
          for (let suppliers_type_item of this.supplier_items[supplier_type]) {
            if (suppliers_type_item.id == this.upscaleProductSupplierId) {
              upscaleProductSupplierDataFromSuppliersItemsData = {
                ...suppliers_type_item,
              };
              break;
            }
          }
        }
      }

      let upscaleProductSupplierDataFromSuppliersData = null;
      if (this.suppliers && this.upscaleProductSupplierId in this.suppliers) {
        upscaleProductSupplierDataFromSuppliersData = {
          ...this.suppliers[this.upscaleProductSupplierId],
        };
      }

      let upscaleProductSupplierData = upscaleProductSupplierDataFromSuppliersData
        ? upscaleProductSupplierDataFromSuppliersData
        : upscaleProductSupplierDataFromSuppliersItemsData;
      return upscaleProductSupplierData ? upscaleProductSupplierData : null;
    },
    formattedPhoneNumber() {
      let formattedPhoneNumber = this.payment.phone_number;

      if (this.payment.phone_number.length > 3) {
        formattedPhoneNumber =
          this.payment.phone_number.substr(0, 3) +
          '-' +
          this.payment.phone_number.substr(3);
      }

      formattedPhoneNumber = '<b>' + formattedPhoneNumber + '</b>';

      return formattedPhoneNumber;
    },
    supplierProductsAll() {
      let supplierProducts = [];
      if (
        this.suppliers_products &&
        this.supplierId in this.suppliers_products
      ) {
        let supplerProductsByType = this.suppliers_products[this.supplierId];

        if (supplerProductsByType) {
          for (let supplier_product_type in supplerProductsByType) {
            for (let supplier_product of supplerProductsByType[
              supplier_product_type
            ]) {
              supplierProducts.push(supplier_product);
            }
          }
        }
      }
      supplierProducts.sort((a,b) => (a.price > b.price) ? 1 : ((b.price > a.price) ? -1 : 0)); 
      return supplierProducts ? supplierProducts : [];
    },
    expensiveProduct() {
      if (this.productData && this.supplierProductsAll && this.supplierProductsAll.length) {
        let expensiveArray = [];
        for (let product of this.supplierProductsAll) {
          if (product && product.price > this.productData.price) {
            expensiveArray.push(product);
            if (expensiveArray.length == 4) {
              break;
            }
          }
        }
        return expensiveArray;
      }
    },

  },
  async created() {
    this.getProduct({ product_id: this.productId });
    this.getSupplier({ supplier_id: this.supplierId });

    if (this.productData && this.productData.upscale_product_id) {
      this.getProduct({ product_id: this.productData.upscale_product_id });
    }

    if (
      this.upscaleProductData &&
      this.upscaleProductData.supplier_id &&
      this.supplierId &&
      this.upscaleProductData.supplier_id != this.supplierId
    ) {
      this.getSupplier({ supplier_id: this.upscaleProductData.supplier_id });
    }

    if (!this.suppliers_products || this.suppliers_products.length == 0) {
      await this.getSupplierProducts({ supplier_id: this.supplierId });
    }
  },
  watch: {
    upscaleProductId(upscaleProductId) {
      if (!upscaleProductId) {
        return;
      }
      this.getProduct({ product_id: upscaleProductId });
    },
    upscaleProductSupplierId(upscaleProductSupplierId) {
      if (!upscaleProductSupplierId) {
        return;
      }
      this.getSupplier({ supplier_id: upscaleProductSupplierId });
    },
  },
  methods: {
    ...mapActions('product', {
      getProduct: 'getProduct',
    }),
    ...mapActions('supplier', {
      getSupplier: 'getSupplier',
      getSupplierProducts: 'getSupplierProducts'
    }),
    ...mapActions('payment', {
      paymentProduct: 'paymentProduct',
    }),
    getApiURL() {
      return appConfig.apiUrl;
    },
    handlePaymentSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      if (this.payment.confirmed) {
        const phone_number = this.payment.phone_number;
        const contract_number = this.payment.phone_number;
        const supplier_id = this.supplierId;
        const product_id = this.productId;
        if (supplier_id && product_id && phone_number && contract_number) {
          this.paymentProduct({
            supplier_id,
            product_id,
            contract_number,
            phone_number,
          }).then(
            (result_data) => {},
            (error) => {},
          );
        }
        this.payment.confirmed = false;
      } else {
        this.openPaymentConfirmationModal();
      }
    },
    openPaymentConfirmationModal() {
      this.paymentConfirmationModal.active = true;
      $('html').css('overflow', 'hidden');
    },
    closePaymentConfirmationModal() {
      this.paymentConfirmationModal.active = false;
      $('html').css('overflow', 'auto');
    },
    cancelPaymentHandler() {
      this.payment.confirmed = false;
      this.payment.phone_number = '';
      this.closePaymentConfirmationModal();
    },
    approvePaymentHandler() {
      this.payment.confirmed = true;
      this.handlePaymentSubmit();
      this.closePaymentConfirmationModal();
    },
    goUpgradeProduct() {
      this.$router.push({
        name: 'CommunicationProduct', 
        params: {
          id: this.expensiveProduct[0].id,
          supplier_id: this.expensiveProduct[0].supplier_id
        }
      })
    }
  },
  updated() {},
};
</script>
<style lang="scss" scoped>
.confirm-phone__button {
  line-height: 40px;
  font-size: 18px;
}
.product {
  padding-top: 20px;
}
.confirm-phone {
  margin-top: 30px;
}

.product__similar {
  position: relative;
  margin-top: 60px;
  &::before {
    content: "";
    width: 100%;
    height: 1px;
    background: #b2bbc2;
    position: absolute;
    top: -30px;
    left: 0;
  }
}

.upgrade-product {
  margin-top: 15px;
  margin-bottom: -10px;
  text-decoration: underline;
  cursor: pointer;
  color: #29abe2;
}

@media screen and (max-width: 767px) {
  .product__title {
    text-align: center;
    font-size: 24px;
    margin-bottom: 20px;
  }
}

.link {
  cursor: pointer;
}

@media screen and (max-width: 1365px) {
  .product__top {
    flex-wrap: wrap-reverse;
    .confirm-phone {
      margin-top: 0;
      position: initial;
      opacity: 1;
      visibility: initial;
      flex-basis: 100%;
      z-index: unset;
    }
    .card {
      max-width: 100%;
    }
  }
}
@media screen and (max-width: 767px) {
  .category__wrapper {
    justify-content: flex-start;
  }
  .category {
    padding: 10px 0;
  }
}
.ltr-type {
  @media (min-width: 1365px) {
    .category__unit:not(:nth-child(4n)) {
      margin-right: 15px;
      margin-left: 0;
    }
  }
}
</style>