var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card__background communication-product__background p-relative",style:({
    backgroundColor: _vm.isExpensive ? _vm.supplierData.chosen_card_background_color : _vm.supplierData.product_card_background_color 
  })},[_c('div',{staticClass:"communication-product__communication-logo-wrapper",style:({
      'background-color': _vm.supplierData.product_side_logo_background_color,
    })},[_c('img',{staticClass:"img",attrs:{"src":_vm.supplierData.image,"alt":""}})]),_c('p',{staticClass:"valid-days"},[_vm._v(" "+_vm._s(_vm.$t('COMMUNICATION.VALID_ON_DAYS'))+": "+_vm._s(_vm.productData.usage_time_limit)+" ")]),(!_vm.productData.add_vat)?_c('p',{staticClass:"no-vat-remark"},[_vm._v(" "+_vm._s(_vm.$t('COMMUNICATION.PRODUCT_WITH_NO_VAT'))+" ")]):_vm._e(),(_vm.productData.show_additional_details)?_c('img',{staticClass:"infor-icon",attrs:{"src":"/assets/img/icons/info.svg","alt":""},on:{"click":function($event){return _vm.openInforPopup()}}}):_vm._e(),_c('div',{staticClass:"price-block"},[_c('span',[_vm._v(_vm._s(_vm.$t('COMMON.PRICE'))+":")]),_c('b',{staticClass:"fs25"},[_vm._v(_vm._s(' ' + _vm.productData.price + ' '))]),_c('b',{staticClass:"card__current"},[_vm._v("₪")])]),_c('b',{staticClass:"card__product-name communication-product__product-name",style:({
      color: _vm.isExpensive ? _vm.supplierData.chosen_product_font_color : _vm.supplierData.product_font_color,
    })},[_vm._v(" "+_vm._s(_vm.productData.name)+" ")]),_c('div',{staticClass:"category-detail_block m-t-10",style:({
      color: _vm.isExpensive ? _vm.supplierData.chosen_product_font_color : _vm.supplierData.product_font_color,
    })},[(_vm.productData.call_minute)?_c('p',[_c('b',[_vm._v(_vm._s(_vm.$t('MANAGE_PRODUCT.MINUTES_OF_CONVERSATION'))+": ")]),_vm._v(" "+_vm._s(_vm.productData.call_minute)+" ")]):_vm._e(),(_vm.productData.sms)?_c('p',[_c('b',[_vm._v(_vm._s(_vm.$t('COMMUNICATION.MESSAGES'))+": ")]),_vm._v(" "+_vm._s(_vm.productData.sms)+" ")]):_vm._e(),(_vm.productData.abroed_price)?_c('p',[_c('b',[_vm._v(_vm._s(_vm.$t('COMMUNICATION.PRICE_USE_ABROAD_ISRAEL'))+": ")]),_vm._v(" "+_vm._s(_vm.productData.abroed_price)+" ")]):_vm._e(),(_vm.productData.other1)?_c('p',[_c('b',[_vm._v(_vm._s(_vm.$t('COMMUNICATION.OTHER_1'))+": ")]),_vm._v(" "+_vm._s(_vm.productData.other1)+" ")]):_vm._e(),(_vm.productData.browsing_package)?_c('p',[_c('b',[_vm._v(_vm._s(_vm.$t('COMMUNICATION.SURFING'))+": ")]),_vm._v(" "+_vm._s(_vm.productData.browsing_package)+" ")]):_vm._e(),(_vm.productData.other2)?_c('p',[_c('b',[_vm._v(_vm._s(_vm.$t('COMMUNICATION.OTHER_2'))+": ")]),_vm._v(" "+_vm._s(_vm.productData.other2)+" ")]):_vm._e()]),_c('InforPopup',{attrs:{"inforData":_vm.inforData,"modal_key":_vm.modal_key}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }