<template>
  <div>
    <div
      class="category__bg"
      :style="{ backgroundColor: isSupplierCategoryItem ? supplierData.product_card_background_color : supplierData.chosen_card_background_color }"
    >
      <p v-if="!productItem.add_vat" class="no-vat-remark">
        {{ $t('COMMUNICATION.PRODUCT_WITH_NO_VAT') }}
      </p>
      <CategoryShare></CategoryShare>
      <div
        class="category__star"
        @click="changeFavorite(productItem.id)"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="26"
          height="25"
          viewBox="0 0 26 25"
        >
          <path
            :style="{ fill: productItem.favorite ? supplierData.favorite_star_color : '#fff'}"
            d="M32.158.857,28.97,7.321l-7.133,1.04a1.563,1.563,0,0,0-.864,2.666l5.161,5.029-1.221,7.1A1.561,1.561,0,0,0,27.178,24.8l6.381-3.354L39.941,24.8a1.563,1.563,0,0,0,2.265-1.645l-1.221-7.1,5.16-5.029a1.563,1.563,0,0,0-.864-2.666l-7.133-1.04L34.961.857a1.564,1.564,0,0,0-2.8,0Z"
            transform="translate(-20.5 0.013)"
          />
        </svg>
      </div>
      <img 
        src="/assets/img/icons/info.svg" 
        alt="" 
        class="infor-icon"
        @click="openInforPopup()"
        v-if="productItem.show_additional_details"
      >

      <div class="communication-category__unit-caption">
        <div
          class="communication-category__product-name"
          :style="{ color: isSupplierCategoryItem ? supplierData.product_font_color : supplierData.chosen_product_font_color }"
        >
          {{ productItem.name }}
        </div>
      </div>

      <div
        :style="{
          'background-color': supplierData.product_side_logo_background_color,
        }"
        class="communication-category__logo-wrapper"
      >
        <img class="img" :src="supplierData.image" alt="" />
      </div>
      <div class="valid_day-price">
        <div class="valid-day">
          {{ $t('COMMUNICATION.VALID_ON_DAYS') }}:
          {{ getValidDay(productItem.id) }}
        </div>
        <div>
          <span class="category__price">{{ $t('COMMON.PRICE')}}: </span>
          <b class="category__price-value">
            {{ productItem.price }} ₪
          </b>
        </div>
      </div>
    </div>

    <div class="category__info">
      <p class="text category__text" v-html="productItem.description"></p>
      <div class="category-detail_block m-b-10">
        <p v-if="productItem.call_minute">
          <b>{{ $t('MANAGE_PRODUCT.MINUTES_OF_CONVERSATION') }}:</b>
          {{ productItem.call_minute }}
        </p>
        <p v-if="productItem.sms">
          <b>{{ $t('COMMUNICATION.MESSAGES') }}:</b> {{ productItem.sms }}
        </p>
        <p v-if="productItem.abroed_price">
          <b>{{ $t('COMMUNICATION.PRICE_USE_ABROAD_ISRAEL') }}:</b>
          {{ productItem.abroed_price }}
        </p>
        <p v-if="productItem.other1">
          <b>{{ $t('COMMUNICATION.OTHER_1') }}:</b> {{ productItem.other1 }}
        </p>
        <p v-if="productItem.browsing_package">
          <b>{{ $t('COMMUNICATION.SURFING') }}:</b>
          {{ productItem.browsing_package }}
        </p>
        <p v-if="productItem.other2">
          <b>{{ $t('COMMUNICATION.OTHER_2') }}:</b> {{ productItem.other2 }}
        </p>
      </div>
      <router-link
        :to="{
          name: 'CommunicationProduct',
          params: {
            id: productItem.id,
            supplier_id: productItem.supplier_id,
          },
        }"
        tag="a"
        class="link category__button pointer"
        exact
        >{{ $t('COMMON.TIME') }}</router-link
      >
    </div>
    <InforPopup :inforData="inforData" :modal_key="modal_key"></InforPopup>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import CategoryShare from './CategoryShare'
import InforPopup from './InforPopup'
export default {
  name: 'CategoryUnit',
  components: {
    CategoryShare,
    InforPopup
  },
  props: {
    productItem: {
      type: Object,
      required: true
    },
    supplierData: {
      type: Object,
      required: true
    },
    productData: {
      type: [Array, Object],
      required: true
    },
    supplierId: {
      type: [Number, String],
      required: true
    },
    isSupplierCategoryItem: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      modal_key: +new Date()
    }
  },
  computed: {
    inforData() {
      if (this.productItem.additional_details=="" || !this.productItem.additional_details) {
        return this.productItem.additional_details_default;
      } else {
        return this.productItem.additional_details;
      }
    }
  },
  methods: {
    ...mapActions('supplier', {
      setFavoriteProduct: 'setFavoriteProduct'
    }),
    getValidDay(id) {
      if (Array.isArray(this.productData) && this.productData.length !=0 ) {
        let valid_day = this.productData.filter((product) => {
          return product.id == id;
        })
        return valid_day[0].usage_time_limit;
      } else {
        return this.productData.usage_time_limit;
      }
    },
    changeFavorite(id) {
      if (this.productItem) {
        let value = !this.productItem.favorite;
        this.setFavoriteProduct({product_id: id, supplier_id: this.supplierId, favorite: value});
      }
    },
    /* <!-- ------------information popup-------------- --> */
    openInforPopup() {
      this.$modal.show("inforPopup_"+this.modal_key);
    },    

  }

};
</script>

<style lang="scss">
.category-detail_block {
  text-align: right;
  height: 150px;
  p {
    margin-bottom: 0;
    height: 24px;
    overflow: hidden;
  }
}

.ltr-type {
  .category-detail_block {
    text-align: left;
  }
}
</style>

<style lang="scss" scoped>
.no-vat-remark {
  position: absolute;
  top: 25px;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: bold;
  font-size: 24px;
  color: #fff;
}
.communication-category__unit-caption {
  bottom: 80px;
  padding-right: 15px;
  padding-left: 55px;
  .communication-category__product-name {
    height: 52px;
  }
}
.communication-category__unit-caption::before {
  content: none;
  display: none;
}

.communication-category__logo-wrapper {
  width: 47px;
  height: 120px;
  position: absolute;
  bottom: 35px;
  left: 0;
  line-height: 120px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  img {
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
    max-height: 40px;
    vertical-align: middle;
  }
}

.valid_day-price {
  display: flex;
  justify-content: space-between;
  position: absolute;
  align-items: baseline;
  bottom: 0;
  color: #ffffff;
  font-size: 14px;
  padding: 0 15px;
  width: 100%;
  .category__price-value {
    font-size: 24px;
  }
  .category__current {
    font-size: 16px;
  }
}
.category__bg {
  height: 220px;
}
.category__info {
  padding-bottom: 10px;
}

.infor-icon {
  position: absolute;
  top: 13px;
  left: 55px;
  cursor: pointer;
  width: 24px;
  height: 24px;
}

@media screen and (max-width: 767px) {
  .communication-category__unit-caption {
    padding-right: 70px;
    position: initial;
    .communication-category__product-name {
      font-size: 18px;
      height: unset;
      margin-top: 10px;
    }
    .communication-category__current {
      font-size: 16px;
    }
  }
  .valid_day-price { 
    padding: 0 15px;
  }
  .communication-category__logo-wrapper {
    display: none;
  }
  .category__bg {
    padding: 10px;
    height: 110px;
  }
}

.ltr-type {
  .communication-category__logo-wrapper {
    left: unset;
    right: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    img {
      transform: rotate(-90deg);
    }
  }
  .communication-category__unit-caption[data-v-007835eb] {
    padding-left: 15px;
    padding-right: 55px;
  }
}
</style>