<template>
  <div
    class="card__background communication-product__background p-relative"
    :style="{
      backgroundColor: isExpensive ? supplierData.chosen_card_background_color : supplierData.product_card_background_color 
    }"
  >
    <div
      :style="{
        'background-color': supplierData.product_side_logo_background_color,
      }"
      class="communication-product__communication-logo-wrapper"
    >
      <img class="img" :src="supplierData.image" alt="" />
    </div>

    <p class="valid-days">
      {{ $t('COMMUNICATION.VALID_ON_DAYS') }}:
      {{ productData.usage_time_limit }}
    </p>
    <p v-if="!productData.add_vat" class="no-vat-remark">
      {{ $t('COMMUNICATION.PRODUCT_WITH_NO_VAT') }}
    </p>
    <img
      src="/assets/img/icons/info.svg" 
      alt="" 
      class="infor-icon"
      @click="openInforPopup()"
      v-if="productData.show_additional_details"
    >
    <div class="price-block">
      <span>{{ $t('COMMON.PRICE') }}:</span>
      <b class="fs25">{{ ' ' + productData.price + ' ' }}</b>
      <b class="card__current">₪</b>
    </div>

    <b
      class="card__product-name communication-product__product-name"
      :style="{
        color: isExpensive ? supplierData.chosen_product_font_color : supplierData.product_font_color,
      }"
    >
      {{ productData.name }}
    </b>

    <div
      class="category-detail_block m-t-10"
      :style="{
        color: isExpensive ? supplierData.chosen_product_font_color : supplierData.product_font_color,
      }"
    >
      <p v-if="productData.call_minute">
        <b>{{ $t('MANAGE_PRODUCT.MINUTES_OF_CONVERSATION') }}: </b>
        {{ productData.call_minute }}
      </p>
      <p v-if="productData.sms">
        <b>{{ $t('COMMUNICATION.MESSAGES') }}: </b> {{ productData.sms }}
      </p>
      <p v-if="productData.abroed_price">
        <b>{{ $t('COMMUNICATION.PRICE_USE_ABROAD_ISRAEL') }}: </b>
        {{ productData.abroed_price }}
      </p>
      <p v-if="productData.other1"> 
        <b>{{ $t('COMMUNICATION.OTHER_1') }}: </b> {{ productData.other1 }}
      </p>
      <p v-if="productData.browsing_package">
        <b>{{ $t('COMMUNICATION.SURFING') }}: </b>
        {{ productData.browsing_package }}
      </p>
      <p v-if="productData.other2">
        <b>{{ $t('COMMUNICATION.OTHER_2') }}: </b> {{ productData.other2 }}
      </p>
    </div>
    <InforPopup :inforData="inforData" :modal_key="modal_key"></InforPopup>
  </div>
</template>

<script>
import InforPopup from './InforPopup'

export default {
  name: 'ProductCard',
  components: {
    InforPopup
  },
  props: {
    supplierData: {
      type: Object,
      required: true
    },
    productData: {
      type: Object,
      required: true
    },
    isExpensive: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      modal_key: +new Date()
    }
  },
  computed: {
    inforData() {
      if (this.productData.additional_details=="" || !this.productData.additional_details) {
        return this.productData.additional_details_default;
      } else {
        return this.productData.additional_details;
      }
    }
  },
  methods: {
     /* <!-- ------------information popup-------------- --> */
    openInforPopup() {
      this.$modal.show("inforPopup_"+this.modal_key);
    },  
  }
};
</script>

<style lang="scss" scoped>
.card__background {
  height: 240px;
}
.communication-product__background::before,
.communication-product__more::before {
  content: none;
  display: none;
}
.communication-product__communication-logo-wrapper {
  width: 85px;
  height: 200px;
  position: absolute;
  top: calc(50% - 100px);
  left: 0;
  line-height: 200px;
  border-top-right-radius: 35px;
  border-bottom-right-radius: 35px;
  img {
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);
    max-height: 55px;
    vertical-align: middle;
  }
}
.communication-product__product-name {
  font-size: 24px;
}
.valid-days,
.no-vat-remark {
  position: absolute;
  top: 10px;
  left: 120px;
  color: #fff;
}
.no-vat-remark {
  top: 35px;
  font-size: 24px;
  font-weight: bold;
}
.price-block {
  position: absolute;
  bottom: 20px;
  left: 120px;
  color: #fff;
}
.infor-icon {
  position: absolute;
  top: 13px;
  left: 85px;
  cursor: pointer;
  width: 20px;
  height: 20px;
}

@media screen and (max-width: 767px) {
  .card__background {
    height: auto;
    padding-bottom: 10px;
    padding-left: 50px;
  }
  .communication-product__communication-logo-wrapper {
    width: 50px;
    height: 140px;
    position: absolute;
    top: 45px;
    left: 0;
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
    img {
      width: 90px;
      position: absolute;
      top: 46px;
      left: -22px;
      max-height: 49px;
    }
  }
  .valid-days,
  .price-block,
  .no-vat-remark {
    left: 50px;
  }
  .infor-icon {
    left: 15px;
  }
}

/* category css */
.category-detail_block {
  text-align: right;
  height: 150px;
  p {
    margin-bottom: 0;
    height: 24px;
    overflow: hidden;
  }
}
.ltr-type {
  .category-detail_block {
    text-align: left;
  }
  .communication-product__communication-logo-wrapper {
    left: unset;
    right: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 35px;
    border-bottom-left-radius: 35px;
  }
  .communication-product__background {
    padding-right: 0;
    padding-left: 47px;
  }
  .valid-days, 
  .price-block,
  .no-vat-remark {
    left: unset;
    right: 120px;
  }
  .infor-icon {
    left: unset;
    right: 85px;
  }
  @media screen and (max-width: 767px) {
    .card__background {
      padding-left: 30px;
      padding-right: 50px;
    }
    .valid-days,
    .price-block,
    .no-vat-remark {
      right: 50px;
    }
    .no-vat-remark {
      top: 83px
    }
    .communication-product__communication-logo-wrapper {
      img {
        left: unset;
        right: -21px;
      }
    }
    .infor-icon {
      left: unset;
      right: 15px;
    }
  }
}
</style>