<template>
  <div class="card__socials">
    <b class="card__share">{{ $t('TOURISM_PRODUCT.SHARE_OVER') }}</b>

    <div class="card__socials-wrapper">
      <a class="link card__social" href="">
        <svg
          class="category__whatsapp"
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          viewBox="0 0 30 30"
        >
          <defs:style>
            .whatsapp { fill: #29abe2; }
          </defs:style>
          <path
            class="whatsapp"
            d="M25.507,36.359A14.871,14.871,0,0,0,2.109,54.3L0,62l7.882-2.069a14.821,14.821,0,0,0,7.1,1.808h.007A14.772,14.772,0,0,0,25.507,36.359ZM14.993,59.234A12.334,12.334,0,0,1,8.7,57.513l-.449-.268L3.576,58.471l1.246-4.56-.295-.469a12.38,12.38,0,1,1,22.962-6.569A12.494,12.494,0,0,1,14.993,59.234ZM21.77,49.98c-.368-.187-2.2-1.085-2.538-1.205s-.589-.187-.837.188-.958,1.205-1.179,1.46-.435.281-.8.094a10.111,10.111,0,0,1-5.056-4.42c-.382-.656.382-.609,1.092-2.029a.688.688,0,0,0-.033-.65c-.094-.187-.837-2.016-1.145-2.759-.3-.723-.609-.623-.837-.636s-.462-.013-.71-.013a1.376,1.376,0,0,0-.991.462,4.174,4.174,0,0,0-1.3,3.1,7.277,7.277,0,0,0,1.513,3.844,16.6,16.6,0,0,0,6.348,5.612c2.357,1.018,3.281,1.1,4.46.931a3.8,3.8,0,0,0,2.5-1.768,3.107,3.107,0,0,0,.214-1.768C22.386,50.254,22.138,50.161,21.77,49.98Z"
            transform="translate(0 -32)"
          />
        </svg>
      </a>

      <a class="link card__social" href="">
        <svg
          class="category__sms"
          xmlns="http://www.w3.org/2000/svg"
          width="34"
          height="30"
          viewBox="0 0 34 30"
        >
          <defs:style>
            .sms { fill: #29abe2; }
          </defs:style>
          <path
            class="sms"
            d="M20.3,41.643H19.224a1.066,1.066,0,0,0-.958.589L17.081,44.6,15.9,42.232a1.066,1.066,0,0,0-.958-.589H13.867A1.075,1.075,0,0,0,12.8,42.714v6.964a.537.537,0,0,0,.536.536H14.4a.537.537,0,0,0,.536-.536V45.112L16.6,48.848a.535.535,0,0,0,.958,0l1.661-3.737v4.567a.537.537,0,0,0,.536.536h1.071a.537.537,0,0,0,.536-.536V42.714A1.065,1.065,0,0,0,20.3,41.643ZM9.005,44.489a.343.343,0,0,1-.141-.254c0-.208.3-.442.7-.442h.824a.537.537,0,0,0,.536-.536V42.185a.537.537,0,0,0-.536-.536H9.568a2.723,2.723,0,0,0-2.839,2.585,2.487,2.487,0,0,0,.891,1.882l1.467,1.259a.343.343,0,0,1,.141.254c0,.208-.3.442-.7.442H7.706a.537.537,0,0,0-.536.536v1.071a.537.537,0,0,0,.536.536H8.53a2.723,2.723,0,0,0,2.839-2.585,2.487,2.487,0,0,0-.891-1.882L9.005,44.489Zm16.071,0a.343.343,0,0,1-.141-.254c0-.208.3-.442.7-.442h.824a.537.537,0,0,0,.536-.536V42.185a.537.537,0,0,0-.536-.536h-.824a2.723,2.723,0,0,0-2.839,2.585,2.487,2.487,0,0,0,.891,1.882l1.467,1.259a.343.343,0,0,1,.141.254c0,.208-.3.442-.7.442h-.817a.537.537,0,0,0-.536.536v1.071a.537.537,0,0,0,.536.536H24.6a2.723,2.723,0,0,0,2.839-2.585,2.487,2.487,0,0,0-.891-1.882ZM17.081,32C7.612,32-.062,38.234-.062,45.929A12.31,12.31,0,0,0,3.48,54.386a16.993,16.993,0,0,1-3.107,4.9A1.608,1.608,0,0,0,1.539,62a16.248,16.248,0,0,0,9.315-3.1,20.759,20.759,0,0,0,6.228.958c9.469,0,17.143-6.234,17.143-13.929S26.55,32,17.081,32Zm0,25.714a18.47,18.47,0,0,1-5.571-.857l-1.018-.321-.871.616A14.449,14.449,0,0,1,2.751,59.8a18.065,18.065,0,0,0,2.732-4.661l.475-1.252-.917-.978a10.143,10.143,0,0,1-2.953-6.984c0-6.5,6.73-11.786,15-11.786s15,5.29,15,11.786S25.351,57.714,17.081,57.714Z"
            transform="translate(0.066 -32)"
          />
        </svg>
      </a>

      <a class="link card__social" href="">
        <svg
          class="category__instagram"
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          viewBox="0 0 30 30"
        >
          <defs:style>
            .instagram { fill: #29abe2; }
          </defs:style>
          <path
            class="instagram"
            d="M14.932,39.133a7.692,7.692,0,1,0,7.692,7.692A7.679,7.679,0,0,0,14.932,39.133Zm0,12.692a5,5,0,1,1,5-5,5.01,5.01,0,0,1-5,5Zm9.8-13.007a1.794,1.794,0,1,1-1.794-1.794A1.79,1.79,0,0,1,24.732,38.819Zm5.094,1.821A8.878,8.878,0,0,0,27.4,34.354a8.937,8.937,0,0,0-6.286-2.423c-2.477-.141-9.9-.141-12.378,0a8.924,8.924,0,0,0-6.286,2.417A8.907,8.907,0,0,0,.03,40.633c-.141,2.477-.141,9.9,0,12.378A8.878,8.878,0,0,0,2.454,59.3,8.948,8.948,0,0,0,8.74,61.72c2.477.141,9.9.141,12.378,0A8.878,8.878,0,0,0,27.4,59.3a8.937,8.937,0,0,0,2.423-6.286C29.967,50.534,29.967,43.116,29.826,40.64Zm-3.2,15.028a5.063,5.063,0,0,1-2.852,2.852c-1.975.783-6.661.6-8.843.6s-6.875.174-8.843-.6a5.063,5.063,0,0,1-2.852-2.852c-.783-1.975-.6-6.661-.6-8.843s-.174-6.875.6-8.843A5.063,5.063,0,0,1,6.089,35.13c1.975-.783,6.661-.6,8.843-.6s6.875-.174,8.843.6a5.063,5.063,0,0,1,2.852,2.852c.783,1.975.6,6.661.6,8.843S27.41,53.7,26.626,55.668Z"
            transform="translate(0.075 -31.825)"
          />
        </svg>
      </a>

      <a class="link card__social" href="">
        <svg
          class="category__facebook"
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="30"
          viewBox="0 0 16 30"
        >
          <defs:style>
            .facebook { fill: #29abe2; }
          </defs:style>
          <path
            class="facebook"
            d="M37.9,16.875l.833-5.429h-5.21V7.922a2.715,2.715,0,0,1,3.061-2.933h2.368V.367A28.882,28.882,0,0,0,34.753,0c-4.29,0-7.095,2.6-7.095,7.308v4.138H22.89v5.429h4.769V30h5.869V16.875Z"
            transform="translate(-22.89)"
          />
        </svg>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CardSocials',
};
</script>

<style lang="scss" scoped>
.ltr-type {
  .card__share {
    margin-left: 0;
    margin-right: 25px;
  }
}
</style>